import React from 'react';
import '../../styles/components/common/button.scss';
import Button from './Button';

const ResumeButton = () => (
  <Button
    text="Resumé"
    handleClick={() => {
      window.open('https://drive.google.com/file/d/1EsdiIiDHEQI79tiDO0PO0MQGoao9_bQt/view?usp=sharing', '_blank');
    }}
  />
);
export default ResumeButton;
